import React from 'react'

const Register = () => {

  return (
    <>
        register

    </>
  )
}

export default Register