import React from 'react'

const Login = () => {

  return (
    <>
        <div className="login">
            Login
        </div>

    </>
  )
}

export default Login